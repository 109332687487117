import React, { useEffect, useState } from 'react';
import VideoPlayer from './VideoPlayer';

function App() {
  const [videoSrc, setVideoSrc] = useState('');
  const [position, setPosition] = useState('right');

  useEffect(() => {
    const rootElement = document.getElementById('react-video-player-root');
    if (rootElement) {
      const videoId = rootElement.getAttribute('data-video-id');
      const pos = rootElement.getAttribute('data-position');
      
      if (videoId) setVideoSrc(videoId);
      if (pos) setPosition(pos);
    }
  }, []);

  if (!videoSrc) {
    console.log('No video source provided');
    return null;
  }

  return (
    <div className="App">
      <VideoPlayer 
        videoSrc={videoSrc}
        position={position}
      />
    </div>
  );
}

export default App;
