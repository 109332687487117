import React, { useState, useRef, useEffect } from 'react';

const VideoPlayer = ({ videoSrc, position = 'bottom-right' }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isVertical, setIsVertical] = useState(false);
  const videoRef = useRef(null);

  const toggleExpand = () => {
    setIsExpanded((prev) => !prev);
    if (videoRef.current) {
      videoRef.current.currentTime = 0;
      videoRef.current.muted = isExpanded;
    }
  };

  useEffect(() => {
    const playVideo = () => {
      if (videoRef.current) {
        videoRef.current.play().catch(error => console.error('Error attempting to play', error));
      }
    };
    
    playVideo();
    window.addEventListener('focus', playVideo);
    return () => window.removeEventListener('focus', playVideo);
  }, []);

  useEffect(() => {
    const checkOrientation = () => {
      if (videoRef.current) {
        const { videoWidth, videoHeight } = videoRef.current;
        setIsVertical(videoHeight > videoWidth);
      }
    };

    if (videoRef.current) {
      videoRef.current.addEventListener('loadedmetadata', checkOrientation);
    }

    return () => {
      if (videoRef.current) {
        videoRef.current.removeEventListener('loadedmetadata', checkOrientation);
      }
    };
  }, []);

  const positionStyles = {
    position: 'fixed',
    bottom: '20px',
    ...(position === 'bottom-left' 
      ? { left: '20px' } 
      : { right: '20px' }),
  };

  const expandedStyles = isVertical
    ? { width: '45vh', height: '80vh' }
    : { width: '80vw', height: '45vw' };

  const getExpandedPosition = () => {
    if (position === 'bottom-left') {
      return { left: '20px', bottom: '20px' };
    } else {
      return { right: '20px', bottom: '20px' };
    }
  };

  return (
    <div 
      style={{
        ...positionStyles,
        transition: 'all 0.3s ease',
        ...(isExpanded 
          ? { ...expandedStyles, ...getExpandedPosition(), zIndex: 1000 } 
          : { width: '100px', height: '200px' }),
        overflow: 'hidden',
        borderRadius: isExpanded ? '20px' : '10px', // Увеличенное скругление в развернутом виде
      }}
      onClick={toggleExpand}
    >
      <video
        ref={videoRef}
        src={videoSrc}
        style={{
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          cursor: 'pointer',
        }}
        loop
        playsInline
        muted={!isExpanded}
      />
    </div>
  );
};

export default VideoPlayer;
